import { ObjectFieldTemplateProps } from "@rjsf/utils";
import useUpdateSectionAction from "../../actions/updateSection/useUpdateSectionAction";

function MainImgTemplate(props: ObjectFieldTemplateProps, func: any) {
  const { actionTemplate } = useUpdateSectionAction();
  return (
    <section className="cms-section">
      <h2 className="cms-section-title">{props.title} {actionTemplate} </h2>
      <div className="cms-section-content">
      <div className="inputfile-container">
          {func("img", props)?.content}
        </div>
      </div>
    </section>
  );
}

export default MainImgTemplate;
