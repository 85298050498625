import React, { useState, useEffect, useContext } from "react";
import Select, { ActionMeta, MultiValue } from "react-select";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import index from "@beeldit/core/services";
import { DetailsActionContext } from "@bloonde/cms/cms-pages/actions/details/DetailsActionContext";

interface Option {
  label: string;
  value: number | string;
}

function CustomSelectWidget(props: any) {
  const { id, value, required, disabled, readonly, onChange } = props;
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<Option>>(
    []
  );
  const [options, setOptions] = useState<Option[]>([]);
  const [elementsData, setElementsData] = useState([]);
  const { element } = useContext(DetailsActionContext);

  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (element.pageable_id) {
      index(`houses/${element.pageable_id}/features`)
        .then((response: any) => {
          setElementsData(response.data);
        })
        .catch((error: any) => {
          errorHandler(error, null);
        });
    }
  }, []);

  useEffect(() => {
    const options = elementsData.map((property: any) => {
      return { label: property.text.es, value: property.pivot.feature_id };
    });
    setOptions(options);
    const currentSelectedOptions = options.filter((option) => {
      return value && value.includes(option.value)
    });
    setSelectedOptions(currentSelectedOptions);
  }, [elementsData]);

  const handleOnChange = (
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    setSelectedOptions(newValue);
    const model = newValue.map((option) => {
      return option.value;
    });
    onChange(model);
  };

  const selectStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: "rgba(73, 190, 170, 0.33)",
      backgroundColor: "#E7F5F2",
      borderRadius: "0.25rem",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#70a066",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "#FFFFFF",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "#FFFFFF"
    }),
  };

  return (
    <Select
      id={id}
      isMulti
      options={options}
      value={selectedOptions}
      required={required}
      isDisabled={disabled || readonly}
      onChange={handleOnChange}
      styles={selectStyles}
    />
  );
}

function HouseMainFeaturesSelector(props: any) {
  const { id, options, value, required, disabled, readonly, onChange } = props;
  return (
    <CustomSelectWidget
      id={id}
      options={options.enumOptions}
      value={value}
      required={required}
      disabled={disabled}
      readonly={readonly}
      onChange={onChange}
    />
  );
}

export default HouseMainFeaturesSelector;
