import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import update from "@beeldit/core/services/update";
import { DetailsActionContext } from "../details/DetailsActionContext";
import { DetailsByPageableActionContext } from "../detailsByPageable/DetailsByPageableActionContext";

function useUpdateMainData() {

  const errorHandler = useErrorHandler();

  const {
    element: page,
    setBackendFormErrors,
  } = useContext(DetailsByPageableActionContext);
  const {
    element: page2,
    setBackendFormErrors: setBackendFormErrors2,
  } = useContext(DetailsActionContext);

  const setBackendFormErrorsFinal = page ? setBackendFormErrors : setBackendFormErrors2;
  const pageFinal = page || page2;

  return (pageId: any, data: any, onSuccess: any) => {
    update(`pages/main_data`, pageId, data)
      .then((response: any) => {
        onSuccess(response.data);
        setBackendFormErrorsFinal({});
      })
      .catch((error: any) => {
        // errors.response.data contiene una variable errors que es un objeto donde la key debería ser sections.{index}.content.elements...
        // Sin embargo en errors viene un objeto con key que  es content.elements... por lo que tenemos que cambiar la key
        // Primero tenemos que saber el indice del section dentro del page        
        errorHandler(error, setBackendFormErrorsFinal);
      });
  };
}

export default useUpdateMainData;
