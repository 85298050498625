import { useEffect } from "react";
import { t } from "i18next";
import { Button, Card, Col, Row } from "react-bootstrap";
import useDetailsAction from "../actions/details/useDetailsAction";
import EntriesPage from "@bloonde/cms/cms-entries/pages/EntriesPage";
import { ListActionProvider as EntriesListActionProvider } from "@bloonde/cms/cms-entries/actions/list/ListActionContext";
import { CreateActionProvider as EntriesCreateActionProvider } from "@bloonde/cms/cms-entries/actions/create/CreateActionContext";
import { RemoveActionProvider as EntriesRemoveActionProvider } from "@bloonde/cms/cms-entries/actions/remove/RemoveActionContext";
import { EditActionProvider as EntriesEditActionProvider } from "@bloonde/cms/cms-entries/actions/edit/EditActionContext";

function HeaderDashboardPage() {
  const headerId = 1;

  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useDetailsAction(headerId);

  useEffect(() => {
    getFunction(headerId);
  }, [headerId]);

  /** Filtro con el que inicializamos las entradas de menú asociadas al header */
  const filters = {
    entriesable_id: headerId,
    entriesable_type: "Bloonde\\CMS\\Models\\Header",
  };
  const formFilterValues = {
    entriesable_id: headerId,
    entriesable_type: "Bloonde\\CMS\\Models\\Header",
  };
  const formDefaultValues = {
    entriesable_id: headerId,
    entriesable_type: "Bloonde\\CMS\\Models\\Header",
  };

  return (
    <Row>
      <Col sm={6}>
        <Card className="mb-4">
          <Card.Header>
          <h3 className="card-header-title">{t("header")}</h3>
          </Card.Header>

          <Card.Body>
            <div className="inputfile-container pt-3">
              {detailsActionTemplate}
            </div>
          </Card.Body>
          <Card.Footer className="d-flex py-4">
            <Button variant="primary" className="ms-auto" onClick={onSubmitFunction}>
              {t("save_changes")}
            </Button>
          </Card.Footer>
        </Card>
      </Col>

      <Col sm={6}>
        <EntriesListActionProvider>
          <EntriesRemoveActionProvider>
            <EntriesCreateActionProvider>
              <EntriesEditActionProvider>
                <EntriesPage
                  title="header_entries"
                  filters={filters}
                  formDefaultValues={formDefaultValues}
                  formFilterValues={formFilterValues}
                />
              </EntriesEditActionProvider>
            </EntriesCreateActionProvider>
          </EntriesRemoveActionProvider>
        </EntriesListActionProvider>
      </Col>
    </Row>
  );
}

export default HeaderDashboardPage;
