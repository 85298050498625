import { ObjectFieldTemplateProps } from "@rjsf/utils";
import MainImgTemplate from "./sections/MainImgTemplate ";
import WysiwygTemplate from "./sections/WysiwygTemplate.";
import LocationTemplate from "./sections/LocationTemplate.";
import GalleryTemplate from "./sections/GalleryTemplate";
import SliderTemplate from "./sections/SliderTemplate";
import HouseSummaryTemplate from "./sections/HouseSummaryTemplate";
import HouseFeaturesTemplate from "./sections/HouseFeaturesTemplate";
import YouWillProbablyLikeTemplate from "./sections/YouWillProbablyLikeTemplate";
import { t } from "i18next";
import EventMain from "./sections/EventMain";
import ImgWysiwygLinkElementTemplate from "./sections/ImgWysiwygLinkElementTemplate";
import ImgWysiwygLinkTemplate from "./sections/ImgWysiwygLinkTemplate";
import FooterFeatureTemplate from "./sections/FooterFeatureTemplate";
import GDPRWysiwygTemplate from "./sections/GDPRWysiwygTemplate";
import LogoTemplate from "./sections/LogoTemplate";
import { DotOutline } from "@phosphor-icons/react";
import { Col, Row } from "react-bootstrap";
import useUpdateMainDataAction from "../actions/updateMainData/useUpdateMainDataAction";

function PageFormTemplate(props: ObjectFieldTemplateProps) {
  let id = props.idSchema.$id;
  let type = null;

  if (
    props &&
    props.uiSchema &&
    props.uiSchema["ui:options"] &&
    props.uiSchema["ui:options"].id &&
    typeof props.uiSchema["ui:options"].id === "string"
  ) {
    id = props.uiSchema["ui:options"].id;
  }
  if (
    props &&
    props.uiSchema &&
    props.uiSchema["ui:options"] &&
    props.uiSchema["ui:options"].type &&
    typeof props.uiSchema["ui:options"].type === "string"
  ) {
    type = props.uiSchema["ui:options"].type;
  }
  const { actionTemplate } = useUpdateMainDataAction();
  return (
    <div>
      {id == "root" ? renderForm(props, actionTemplate) : null}
      {id === "root_seo" ? renderSeo(props) : null}
      {type === "translatable" ? renderTranslatableForm(props) : null}
      {type ? renderElement(props, type) : null}
      {id.includes("content") && !type ? renderContent(props) : null}
      {id.includes("sections") ? renderSection(props) : null}
    </div>
  );
}

function renderForm(props: ObjectFieldTemplateProps, actionTemplate?: any) {
  return (
    <div className="PageFormTemplate">
      <div className="cms">
      {
          actionTemplate && (actionTemplate)
        }
        <div className="cms-main">
          <Row>
            <Col sm={8}>
              <div className="item-title">
                {getPropertyByName("title", props)?.content}
              </div>

              <Row>
                <Col sm={9}>
                  <div className="item-slug">
                    {getPropertyByName("slug", props)?.content}
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="item-status">
                    {getPropertyByName("status", props)?.content}
                  </div>
                </Col>
                <div className="item-tag">
                  {getPropertyByName("tag_ids", props)?.content}
                </div>
              </Row>
            </Col>

            <Col sm={4}>
              <div className="item-seo">
                {getPropertyByName("seo", props)?.content}
              </div>
            </Col>
          </Row>
        </div>

        <div className="cms-sections">
          {getPropertyByName("sections", props)?.content}
        </div>
      </div>
    </div>
  );
}

function renderSeo(props: ObjectFieldTemplateProps) {
  return (
    <div className="inputfile-container">
      <div className="inputfile-image">
        {getPropertyByName("image", props)?.content}
      </div>
      <div className="inputfile-title">
        {getPropertyByName("title", props)?.content}
      </div>
      <div className="inputfile-description">
        {getPropertyByName("description", props)?.content}
      </div>
    </div>
  );
}

function renderSection(props: ObjectFieldTemplateProps) {
  return (
    <div
      className="property-wrapper"
      data-section-id={props.formData?.id}
    >
      {getPropertyByName("content", props)?.content}
    </div>
  );
}

function renderContent(props: ObjectFieldTemplateProps) {
  return (
    <div className="gallery-container">
        <div className="property-wrapper">
          {getPropertyByName("elements", props)?.content}
        </div>
    </div>
  );
}

function renderElement(props: ObjectFieldTemplateProps, type: string) {
  if (type === "img_wysiwyg_link") {
    return ImgWysiwygLinkTemplate(props, getPropertyByName);
  } else if (type === "img_wysiwyg_link_element") {
    return ImgWysiwygLinkElementTemplate(props, getPropertyByName);
  } else if (type === "main_img") {
    return MainImgTemplate(props, getPropertyByName);
  } else if (type === "wysiwyg") {
    return WysiwygTemplate(props, getPropertyByName); // Tiene esta estructura porque es una sección única que no pertenece a un array
  } else if (type === "location") {
    return LocationTemplate(props, getPropertyByName); // Tiene esta estructura porque es una sección única que no pertenece a un array
  } else if (type === "gallery" || type === "gallery_with_main") {
    return GalleryTemplate(props, getPropertyByName);
  } else if (type === "slider") {
    return SliderTemplate(props, getPropertyByName);
    // TODO - ROADMAP - Esto debería estar fuera del paquete
  } else if (type === "house_summary") {
    return HouseSummaryTemplate(props, getPropertyByName);
  } else if (type === "house_features") {
    return HouseFeaturesTemplate(props, getPropertyByName);
  } else if (type === "you_will_probably_like") {
    return YouWillProbablyLikeTemplate(props, getPropertyByName);
  } else if (type === "event_main") {
    return EventMain(props, getPropertyByName);
  } else if (type === "footer_features") {
    return FooterFeatureTemplate(props, getPropertyByName);
  } else if (type === 'gdpr_wysiwyg') {
    return GDPRWysiwygTemplate(props, getPropertyByName);
  } else if (type === 'logo') {
    return LogoTemplate(props, getPropertyByName);
  }
}

function renderTranslatableForm(props: ObjectFieldTemplateProps) {
  const langs = ["es", "en", "de"];
  let selectedLang = langs.find((lang) => {
    return (
      props.schema && props.schema.properties && props.schema.properties[lang]
    );
  });
  return selectedLang ? getPropertyByName(selectedLang, props)?.content : null;
}

function getPropertyByName(name: string, props: ObjectFieldTemplateProps) {
  let element = props.properties.find((element: any) => element.name === name);
  // console.log(element);
  return element;
}

export default PageFormTemplate;
