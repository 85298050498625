import { ObjectFieldTemplateProps } from "@rjsf/utils";

function SliderTemplate(props: ObjectFieldTemplateProps, func: any) {
  return (
    <div className="section row">
      <div className="h3">{props.title}</div>
      <div className="col-12">
        <div className="property-wrapper">{func("img", props)?.content}</div>
      </div>
      <div className="col-12">
        <div className="property-wrapper">{func("alt", props)?.content}</div>
      </div>
    </div>
  );
}

export default SliderTemplate;
