import { ObjectFieldTemplateProps } from "@rjsf/utils";
import useUpdateSectionAction from "../../actions/updateSection/useUpdateSectionAction";

function GDPRWysiwygTemplate(props: ObjectFieldTemplateProps, func: any) {

  const {actionTemplate} = useUpdateSectionAction();

  return (
    <div className="cms-section">
      <div className="h3">
        {props.title}{" "} {actionTemplate}
      </div>
      <div className="section row">
        <div className="col-12">
          <div className="property-wrapper">
            {func("wysiwyg", props)?.content}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GDPRWysiwygTemplate;
