import {useContext, useEffect, useState} from "react";
import {t} from "i18next";
import {Card, Tab, Tabs, Nav} from "react-bootstrap";
import {useParams} from "react-router-dom";
import useDetailsAction from "../actions/details/useDetailsAction";
import {DetailsActionProvider as PageDetailsActionContext} from "@bloonde/cms/cms-pages/actions/details/DetailsActionContext";
import PageEditionByPageablePage from "@bloonde/cms/cms-pages/pages/PageEditionByPageablePage";
import {DetailsActionContext} from "@main/houses/actions/details/DetailsActionContext";
import HouseApiInfo from "@main/houses/components/HouseApiInfo";
import ReviewRouterWrapper from "@bloonde/reviews/reviews/wrappers/ReviewRouterWrapper";
import {Database, Pen, ChatTeardropText } from "@phosphor-icons/react";

function HouseDashboardPage() {
    let {houseId} = useParams();

    if (!houseId) {
        houseId = "0";
    }

    const {element} = useContext(DetailsActionContext);

    const {
        getFunction,
    } = useDetailsAction(parseInt(houseId));

    useEffect(() => {
        getFunction(houseId);
    }, [houseId]);

    const filters = {
        house_id: houseId,
        reviewable_id: houseId,
        reviewable_type: "App\\Models\\House",
    };
    const formFilterValues = {
        house_id: houseId,
        reviewable_id: houseId,
        reviewable_type: "App\\Models\\House",
    };
    const formDefaultValues = {
        house_id: houseId,
        reviewable_id: houseId,
        reviewable_type: "App\\Models\\House",
        reviewer_type: "App\\Models\\Customer",
        review_schema_id: 1, // Solo hay uno
    };

    const [contentTabLoaded, setContentTabLoaded] = useState(true);
    const [apiDataTabLoaded, setApiDataTabLoaded] = useState(false);
    const [reviewsTabLoaded, setReviewsTabLoaded] = useState(false);

    const onSelect = (key: string) => {
        if (key === "content") {
            setContentTabLoaded(true);
        } else if (key === "api_data") {
            setApiDataTabLoaded(true);
        } else if (key === "reviews") {
            setReviewsTabLoaded(true);
        }
    };

    // Tabs active state
    const [activeTab, setActiveTab] = useState('content');
    const handleSelect = (eventKey: string | null) => {
        if (eventKey !== null) {
            setActiveTab(eventKey);
        }
    };

    return (
        <div className="HouseDashboardPage">

            <Tab.Container id="left-tabs-example" defaultActiveKey="content" onSelect={(key: any) => {onSelect(key)}}>

                <Nav variant="tabs" className="nav-tabs" onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link
                        eventKey="content"
                        active={activeTab === 'content'}
                        className={activeTab === 'content' ? 'active-tab-class' : ''}
                        >
                        <Pen size={24} />
                        <span className="d-none d-lg-inline">{t('content')}</span>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link
                        eventKey="api_data"
                        active={activeTab === 'api_data'}
                        className={activeTab === 'api_data' ? 'active-tab-class' : ''}
                        >
                        <Database size={24} />
                        <span className="d-none d-lg-inline">{t('api_data')}</span>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link
                        eventKey="reviews"
                        active={activeTab === 'reviews'}
                        className={activeTab === 'reviews' ? 'active-tab-class' : ''}
                        >
                        <ChatTeardropText size={24} />
                        <span className="d-none d-lg-inline">{t('reviews')}</span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="content">
                        {contentTabLoaded && (
                            <PageDetailsActionContext>
                                <PageEditionByPageablePage
                                    pageableId={parseInt(houseId)}
                                    pageableType="App\Models\House"
                                />
                            </PageDetailsActionContext>
                        )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="api_data">
                        {apiDataTabLoaded && (
                            <div className="row">
                                <div className="mb-5">
                                    <Card>
                                        <Card.Body>
                                            <HouseApiInfo pageableData={element}/>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="reviews">
                        {reviewsTabLoaded && (
                            <ReviewRouterWrapper
                                config={{showReviewSchemaSelector: true}}
                                filters={filters}
                                formDefaultValues={formDefaultValues}
                                formFilterValues={formFilterValues}
                            />
                        )}
                    </Tab.Pane>
                </Tab.Content>

            </Tab.Container>
        </div>
    );
}

export default HouseDashboardPage;
