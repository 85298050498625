import { ObjectFieldTemplateProps } from "@rjsf/utils";

function ImgWysiwygLinkTemplate(props: ObjectFieldTemplateProps, func: any) {
  return (
    <div className="cms-section-content">
      {func("elements", props)?.content}
    </div>
  );
}

export default ImgWysiwygLinkTemplate;
