import { ObjectFieldTemplateProps } from "@rjsf/utils";
import useUpdateSectionAction from "../../actions/updateSection/useUpdateSectionAction";
import { Col, Row } from "react-bootstrap";

function FooterFeatureTemplate(props: ObjectFieldTemplateProps, func: any) {
  const {actionTemplate} = useUpdateSectionAction();

  return (
    <section className="cms-section">
      <h2 className="cms-section-title">{props.title} {actionTemplate} </h2>
      <div className="cms-section-content">
        <Row>
          <Col sm={4}>
            {func("footer_feature_1", props)?.content}
          </Col>
          <Col sm={4}>
          {func("footer_feature_2", props)?.content}
          </Col>
          <Col sm={4}>
          {func("footer_feature_3", props)?.content}
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default FooterFeatureTemplate;
