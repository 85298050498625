import { useContext } from "react";
import getConfig from "../configs/tableConfig";
import BeelditTableHeaderActions from "@beeldit/core/components/BeelditTableHeaderActions";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useRemoveAction from "../actions/remove/useRemoveAction";
import useCreateAction from "../actions/create/useCreateAction";
import useEditAction from "../actions/edit/useEditAction";
import useListAction from "../actions/list/useListAction";
import { Card } from "react-bootstrap";

function EntriesPage(props: any) {
  const { filters, formDefaultValues, formFilterValues, title } = props;

  const { user } = useContext(AuthContext);

  const {
    indexFunction,
    actionTemplate: listActionTemplate,
    setTableConfig,
  } = useListAction(user, filters);

  const { initializeFunction, actionTemplate: createActionTemplate } =
    useCreateAction(indexFunction, formDefaultValues, formFilterValues);

  const { getFunction, actionTemplate: editActionTemplate } =
    useEditAction(indexFunction, formFilterValues);

  const { prepareRemoveFunction, actionTemplate: removeActionTemplate } =
    useRemoveAction(indexFunction);


  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let tableConfig = getConfig({
    initializeFunction, // We pass the function to initialize a new element (used in the create button)
    indexFunction, // We pass the function to index the table
    getFunction, // We pass the function to get an element (used in the edit button)
    prepareRemoveFunction,
    /**newActionsTableConfig**/
  });
  setTableConfig(tableConfig);

  return (
    <div className="EntriesPage">
      <Card>
        <Card.Header>
          <h3 className="card-header-title">{t(title)}</h3>
          <div className="card-header-actions">
            <BeelditTableHeaderActions actions={tableConfig.actions} />
          </div>
        </Card.Header>

        <Card.Body>
          <div className="pt-3">
            {listActionTemplate}
            {createActionTemplate}
            {editActionTemplate}
            {removeActionTemplate}
            {/* newTemplateActions */}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default EntriesPage;
