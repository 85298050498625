import { ObjectFieldTemplateProps } from "@rjsf/utils";
import { Col, Row } from "react-bootstrap";

function ImgWysiwygLinkElementTemplate(props: ObjectFieldTemplateProps, func: any) {
  return (
    <Row>
      <Col sm={8}>
        {func("title", props)?.content}
        {func("wysiwyg", props)?.content}
        {func("link", props)?.content}
      </Col>
      <Col sm={4}>
        <div className="inputfile-container">
          {func("img", props)?.content}
        </div>
      </Col>
    </Row>
  );
}

export default ImgWysiwygLinkElementTemplate;
