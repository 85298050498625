import { useEffect } from "react";
import { t } from "i18next";
import { Button, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useDetailsAction from "../actions/details/useDetailsAction";
import TemplateSelector from "@bloonde/cms/cms-templates/components/TemplateSelector";

function PageEditionPage() {
  let { pageId } = useParams();

  if (!pageId) {
    pageId = "0";
  }

  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useDetailsAction(parseInt(pageId));

  useEffect(() => {
    getFunction(pageId);
  }, [pageId]);

  return (
    <Card>
      <Card.Body className="PageEditContainer">
        {detailsActionTemplate}
      </Card.Body>

      <Card.Footer className="d-flex py-4">
        <Button variant="primary" size="lg" className="ms-auto" onClick={onSubmitFunction}>
          {t("save_changes")}
        </Button>
      </Card.Footer>
    </Card>
  );
}

export default PageEditionPage;
