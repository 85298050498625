import { t } from "i18next";
import moment from "moment";
import { Col, ListGroup, Row } from "react-bootstrap";

function HouseApiInfo(props: any) {
  const { pageableData } = props;

  const getFeatureValue = (value: any) => {
    if (value === 1 || value === "true") {
      return t("yes");
    } else if (value === 0 || value === "false") {
      return t("no");
    } else {
      return value;
    }
  }

  return (
    <div>
      {pageableData && (
        <div className="HouseApiInfo mt-4">
          <Row>
            <Col sm={6}>
            <h4 className="list-title">{t("house")}</h4>
              <ListGroup>
                <ListGroup.Item className="d-flex">
                  <strong>{t("name")}</strong>
                  <span className="ms-auto">{pageableData.name}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex">
                  <strong>{t("min_price")}</strong>
                  <span className="ms-auto">{pageableData.max_price ? pageableData.max_price.toFixed(2) : '-'}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex">
                  <strong>{t("max_price")}</strong>
                  <span className="ms-auto">{pageableData.min_price ? pageableData.min_price.toFixed(2) : '-'}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex">
                  <strong>{t("registration_date")}</strong>
                  <span className="ms-auto">{pageableData.registration_date ? moment(pageableData.registration_date, "YYYY-MM-DD").format("DD/MM/YYYY") : '-'}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex">
                  <strong>{t("modified_on")}</strong>
                  <span className="ms-auto">{pageableData.modified_on ? moment(pageableData.modified_on, "YYYY-MM-DD").format("DD/MM/YYYY") : '-'}</span>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={6}>
            <h4 className="list-title">{t("features")}</h4>
              <ListGroup>
                {pageableData.features.map((feature: any) => {
                  return (
                    <ListGroup.Item className="d-flex">
                      <strong>{t(feature.text['en'])}</strong>
                      <span className="ms-auto">{getFeatureValue(feature.pivot.value)}</span>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default HouseApiInfo;
