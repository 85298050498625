import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { t } from "i18next";
import useGetConfigurationAction from "@main/configurations/actions/get/useGetConfigurationAction";
import { ConfigurationsContext } from "@main/contexts/ConfigurationsContext";
import {
  ArrowSquareDown,
  ArrowSquareUp,
  Mountains,
  Browser,
  Sliders,
  HouseLine as House,
} from "@phosphor-icons/react";

function Panel() {
  const { tokenLoaded, user } = useContext(AuthContext);

  const { getConfigurationFunction } = useGetConfigurationAction();
  const { setLangs, setSchemas } = useContext(ConfigurationsContext);

  useEffect(() => {
    if (tokenLoaded) {
      getConfigurationFunction("langs", setLangs);
      getConfigurationFunction("schemas", setSchemas);
    }
  }, [tokenLoaded]);

  if (!tokenLoaded || !user) {
    return <div className="loader">
      <span className="sr-only">Loading...</span>
    </div>;
  }

  const items: any[] = [
    {
      label: t("houses"),
      to: `/panel/houses`,
      icon: <House size={24} />,
    },
    {
      label: t("pages"),
      to: `/panel/pages`,
      icon: <Browser size={24} />,
    },
    {
      label: t("articles"),
      to: `/panel/articles`,
      icon: <Mountains size={24} />,
    },
    {
      label: t("configurations"),
      icon: <Sliders size={24} />,
      children: [
        {
          label: t("header"),
          to: `/panel/header`,
          icon: <ArrowSquareUp size={24} />,
        },
        {
          label: t("footer"),
          to: `/panel/footer`,
          icon: <ArrowSquareDown size={24} />,
        },
      ],
    },
  ];

  return (
    <div>
      <div className="admin container-fluid">
          <Sidebar items={items} />
          <div className="page-content">
            <Outlet />
          </div>
      </div>
    </div>
  );
}

export default Panel;
