import { useEffect } from "react";
import { t } from "i18next";
import { Button, Card } from "react-bootstrap";
import useDetailsByPageableAction from "../actions/detailsByPageable/useDetailsByPageableAction";

function PageEditionByPageablePage(props: {
  pageableId: number;
  pageableType: string;
}) {
  const { pageableId, pageableType } = props;

  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useDetailsByPageableAction(pageableId, pageableType);

  useEffect(() => {
    if (!pageableId && pageableType) return;
    getFunction(pageableId, pageableType);
  }, [pageableId, pageableType]);

  if (!pageableId && !pageableType) return null;

  return (
    <Card>
      <Card.Body>
        <div className="PageEditContainer">
          {detailsActionTemplate}
        </div>
      </Card.Body>

      <Card.Footer className="d-flex py-4">
        <Button variant="primary" size="lg" className="ms-auto" onClick={onSubmitFunction}>
          {t("save_changes")}
        </Button>
      </Card.Footer>
    </Card>
  );
}

export default PageEditionByPageablePage;
