import { Plus, ArrowRight, ArrowLeft } from "@phosphor-icons/react/dist/ssr";
import { ArrayFieldTemplateProps } from "@rjsf/utils";
import { t } from "i18next";
import useUpdateSectionAction from "../../actions/updateSection/useUpdateSectionAction";
import { Button } from "react-bootstrap";

function GalleryArrayTemplate(
  props: ArrayFieldTemplateProps,
  className: string,
  id: string
) {
  const { actionTemplate } = useUpdateSectionAction();

  return (
    <div className={'cms-section '+className}>
      {id !== "root_sections" ? <h2 className="cms-section-title">{props.title} {actionTemplate}</h2> : null}
        <div className="gallery-grid">
          {props.items.map((element, index) => {
            return (
                <div className="gallery-item" key={element.key}>
                  {id !== "root_sections" ? (
                    <div className="gallery-item-header">
                      <h3 className="gallery-item-title sr-only">
                        {t("image")} {index + 1}{" "}
                      </h3>
                      {props.canAdd && (
                        <Button className="gallery-item-remove" variant="light" onClick={element.onDropIndexClick(index)}>
                          <span className="sr-only">{t("delete")}</span>
                        </Button>
                      )}
                    </div>
                  ) : null}

                  <div className="flex-grow-1">{element.children}</div>

                  {/* Botones de ordenación */}
                  <div className="gallery-item-sort">
                    {element.hasMoveUp && (
                      <Button
                        variant="light"
                        className="btn-icon"
                        onClick={element.onReorderClick(index, index - 1)}
                      >
                        <ArrowLeft size={16} />
                        <span className="sr-only">{t("move_up")}</span>
                      </Button>
                    )}
                    {element.hasMoveDown && (
                      <Button
                        variant="light"
                        className="btn-icon"
                        onClick={element.onReorderClick(index, index + 1)}
                      >
                        <ArrowRight size={16} />
                        <span className="sr-only">{t("move_down")}</span>
                      </Button>
                    )}
                  </div>
                </div>
            );
          })}
        </div>

        <div className="text-center mt-4">
          {props.canAdd && (
            <Button
              variant="outline-primary"
              onClick={props.onAddClick}
            >
              <Plus size={24} />
              <span>{t("add_image")}</span>
            </Button>
          )}
        </div>
    </div>
  );
}

export default GalleryArrayTemplate;
