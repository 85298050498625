import { useContext, useEffect, useRef, useState } from "react";
import { showSuccessMessage } from "@beeldit/core/helpers/messagesHelper";
import { FloppyDisk, Spinner } from "@phosphor-icons/react/dist/ssr";
import useUpdateSection from "./useUpdateSection";
import { DetailsActionContext } from "../details/DetailsActionContext";
import { Button } from "react-bootstrap";

function useUpdateSectionAction(): any {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [sectionId, setSectionId] = useState<string | null>("");

  const {
    element: page,
    setElement: setPage,
    selectedLang: selectedLang,
    savingSection,
  } = useContext(DetailsActionContext);

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = (section: any) => {
    showSuccessMessage("successfull_action");
    // Actualizamos la section de la página con el mismo id
    let sectionIndex = page.sections.findIndex(
      (s: any) => s.id === section.id
    );
    page.sections[sectionIndex] = section;
    setPage({ ...page });
  };

  const updateSection = useUpdateSection();
  const updateSectionFunction = () => {
    // Buscamos en finalPage la sección con el id sectionId y hacemos un setSectionId
    let section = page.sections.find((s: any) => s.id == sectionId);
    section.lang = selectedLang;
    updateSection(page.id, sectionId, section, onSuccessUpdate);
  };
  useEffect(() => {
    if (sectionRef.current) {
      // Encontrar el ancestro más cercano con el atributo `data-section-id`
      const ancestor = sectionRef.current.closest("[data-section-id]");
      if (ancestor) {
        let id = ancestor.getAttribute("data-section-id");
        setSectionId(id);
      }
    }
  }, []);

  const actionTemplate = (
    <div ref={sectionRef} className="d-inline">
        {
            savingSection && savingSection === sectionId &&(
                <Spinner size={24} />
            )
        }
        {
            savingSection !== sectionId && (
                <Button
                  className="btn icon-button rounded-circle btn-action"
                  variant="link"
                    onClick={() => {
                        updateSectionFunction();
                    }}
                >
                    <FloppyDisk size={24} />
                </Button>
            )
        }
    </div>
  );

  /** END EDIT FORM ACTION */

  return {
    actionTemplate,
  };
}

export default useUpdateSectionAction;
