import { ObjectFieldTemplateProps } from "@rjsf/utils";
import { t } from "i18next";

function GalleryTemplate(props: ObjectFieldTemplateProps, func: any) {
  return (
    <div className="inputfile-container">
      <div className="inputfile-image">
        {func("img", props)?.content}

        {
          func("main", props)?.content && (
            <div className="inputfile-main" title={t('main_image')}>
              {func("main", props)?.content}
            </div>
          )
        }
      </div>

      <div className="inputfile-alt">
        {func("alt", props)?.content}
      </div>
    </div>
  );
}

export default GalleryTemplate;
