import { useContext, useEffect, useRef, useState } from "react";
import { showSuccessMessage } from "@beeldit/core/helpers/messagesHelper";
import { FloppyDisk } from "@phosphor-icons/react/dist/ssr";
import { DetailsActionContext } from "../details/DetailsActionContext";
import useUpdateMainData from "./useUpdateMainData";
import { DetailsByPageableActionContext } from "../detailsByPageable/DetailsByPageableActionContext";
import { Button } from "react-bootstrap";

function useUpdateMainDataAction(): any {
  const {
    element: page,
    setElement: setPage,
    selectedLang: selectedLang,
  } = useContext(DetailsByPageableActionContext);
  const {
    element: page2,
    setElement: setPage2,
    selectedLang: selectedLang2,
  } = useContext(DetailsActionContext);

  const finalPage = page || page2;
  const finalSelectedLang = selectedLang || selectedLang2;
  const finalSetPage = page ? setPage : setPage2;

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = (section: any) => {
    showSuccessMessage("successfull_action");
  };

  const updateMainData = useUpdateMainData();
  const updateMainDataFunction = () => {
    finalPage.lang = finalSelectedLang;
    updateMainData(finalPage.id, finalPage, onSuccessUpdate);
  };

  const actionTemplate = (
    <div style={{textAlign: 'right'}}>
      <Button
        className="btn icon-button rounded-circle btn-action"
        variant="link"
        onClick={() => {
          updateMainDataFunction();
        }}
      >
        <FloppyDisk size={24} />
      </Button>
    </div>
  );

  /** END EDIT FORM ACTION */

  return {
    actionTemplate,
  };
}

export default useUpdateMainDataAction;
