import { useContext } from "react";
import getConfig from "../configs/tableConfig";
import BeelditTableHeaderActions from "@beeldit/core/components/BeelditTableHeaderActions";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useRemoveAction from "../actions/remove/useRemoveAction";
import useCreateAction from "../actions/create/useCreateAction";
import useListAction from "../actions/list/useListAction";
import { Card } from "react-bootstrap";
import { Browser, Mountains } from "@phosphor-icons/react";
import { useLocation } from 'react-router-dom';


function PagesPage(props: any) {
  const { filters, formDefaultValues, config } = props;

  const { user } = useContext(AuthContext);

  const {
    indexFunction,
    detailsFunction,
    actionTemplate: listActionTemplate,
    setTableConfig,
  } = useListAction(user, filters);

  const { initializeFunction, actionTemplate: createActionTemplate } =
    useCreateAction(indexFunction, formDefaultValues);

  const { prepareRemoveFunction, actionTemplate: removeActionTemplate } =
    useRemoveAction(indexFunction);


  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */

  let tableConfig = getConfig({
    initializeFunction, // We pass the function to initialize a new element (used in the create button)
    indexFunction, // We pass the function to index the table
    detailsFunction, // We pass the function to get an element (used in the edit button)
    prepareRemoveFunction,
    config
    /**newActionsTableConfig**/
  });
  setTableConfig(tableConfig);

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="PagesPage">
      <Card>
        <Card.Header>
          <h2 className="card-header-title">
            {currentPath === "/panel/articles" ? (
              <>
                <Mountains size={24} />
                <span>{t('articles')}</span>
              </>
            ) : currentPath === "/panel/pages" ? (
              <>
                <Browser size={24} />
                <span>{t('pages')}</span>
              </>
            ) : (
              <>
                <span>{t(config.title)}</span>
              </>
            )}
          </h2>
          <div className="card-header-actions">
            <BeelditTableHeaderActions
              actions={tableConfig.actions}
            />
          </div>
        </Card.Header>

        <Card.Body>
          {listActionTemplate}
          {createActionTemplate}
          {removeActionTemplate}
          {/* newTemplateActions */}
        </Card.Body>
      </Card>
  </div>
  );
}

export default PagesPage;
