import { XCircle, ArrowUp, ArrowDown, Plus } from "@phosphor-icons/react/dist/ssr";
import { ArrayFieldTemplateProps } from "@rjsf/utils";
import useUpdateSectionAction from "../../actions/updateSection/useUpdateSectionAction";
import { Button } from "react-bootstrap";
import { t } from "i18next";

function DefaultArrayTemplate(
  props: ArrayFieldTemplateProps,
  className: string,
  id: string
) {
  const { actionTemplate } = useUpdateSectionAction();

  return (
    <>
      <div className={className}>
        {id !== "root_sections" ? (
            <h2 className="cms-section-title">{props.title} {actionTemplate}</h2>
        ) : null}

        {/* Todas las seccions menos la galería */}
        {props.items.map((element, index) => {
          const colClass = element.uiSchema?.colClass || "sections";
          return (
            <>
              {element.schema.properties && (
                <div className={colClass} key={element.key}>
                  {id !== "root_sections" ? (
                    <div className="sections-header">
                      <h3 className="sections-header-title">
                        {props.title} {index + 1}{" "}
                      </h3>
                      <div className="sections-header-sort">
                        {/* Icono para eliminar */}
                        {props.canAdd && (
                          <XCircle
                            size={30}
                            className="pe-hand text-danger"
                            onClick={element.onDropIndexClick(index)}
                          />
                        )}

                        {/* Iconos de ordenación */}
                        {element.hasMoveUp && (
                          <ArrowUp
                            size={30}
                            className="pe-hand ms-2 text-primary"
                            onClick={element.onReorderClick(index, index - 1)}
                          />
                        )}
                        {element.hasMoveDown && (
                          <ArrowDown
                            size={30}
                            className="pe-hand ms-2 text-primary"
                            onClick={element.onReorderClick(index, index + 1)}
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="sections-content">{element.children}</div>
                </div>
              )}
            </>
          );
        })}


        <div className="text-center mt-4">
          <div>
            {props.canAdd && (
              <Button
              variant="outline-primary"
              onClick={props.onAddClick}
            >
              <Plus size={24} />
              <span>{t("add_image")}</span>
            </Button>
            )}
          </div>
        </div>

      </div>
    </>
  );
}

export default DefaultArrayTemplate;
