import { ObjectFieldTemplateProps } from "@rjsf/utils";
import useUpdateSectionAction from "../../actions/updateSection/useUpdateSectionAction";

function WysiwygTemplate(props: ObjectFieldTemplateProps, func: any) {

  const {actionTemplate} = useUpdateSectionAction();

  return (
    <section className="cms-section">
      <h2 className="cms-section-title">
        {props.title}{" "} {actionTemplate}
      </h2>
      <div className="cms-section-content">
        {func("wysiwyg", props)?.content}
      </div>
    </section>
  );
}

export default WysiwygTemplate;
