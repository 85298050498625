import React, { useContext, useEffect, useState } from "react";
import login from "../services/login";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { addToken, getApi } from "@beeldit/core/helpers/api";
import AuthInterceptor from "../interceptors/AuthInterceptor";
import { t } from "i18next";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import {
  showErrorMessage,
  showSuccessMessage,
} from "@beeldit/core/helpers/messagesHelper";
import { Button, Form, NavItem, NavLink } from "react-bootstrap";

interface Prop {
  redirectUrls?: any;
  redirectFunction?: any;
}

const Login = (props: Prop) => {
  const { redirectUrls, redirectFunction } = props;
  const logoName = process.env.REACT_APP_LOGO_NAME;
  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setToken, setUser } = useContext(AuthContext);

  // Obtenemos los query params message y messageType de la url

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get("message");
    const messageType = urlParams.get("messageType");
    if (message && messageType) {
      if (messageType === "error") {
        showErrorMessage(message);
      } else {
        showSuccessMessage(message);
      }
    }
    // Borramos los dos parametros para que no se vuelva a mostrar al refrescacr
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  useEffect(() => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    getApi().defaults.headers.common["tenantId"] = null;
    getApi().defaults.headers.common["commerceId"] = null;
  }, []);

  const navigate = useNavigate();

  const onSuccess = (data: any) => {
    const token = data.token;
    const user = data.user;
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    setToken(JSON.stringify(token));
    setUser(user);
    AuthInterceptor(() => token);
    addToken(token);
    let url = "/panel";
    if (redirectFunction) {
      url = redirectFunction(user);
    } else if (redirectUrls) {
      let role = data.user.role;
      if (redirectUrls && redirectUrls[role]) {
        url = redirectUrls[role];
      }
    }

    navigate(url);
  };

  const errorHandler = useErrorHandler();

  const handleLogin = async (e: any) => {
    let requestResetPassword = {};
    if (emailOrUsername.includes("@")) {
      requestResetPassword = {
        email: emailOrUsername,
        password: password,
      };
    } else {
      requestResetPassword = {
        username: emailOrUsername,
        password: password,
      };
    }
    login(requestResetPassword, onSuccess, errorHandler);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin(event);
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url(" + backgroundSrc + ")",
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
      }}
    >
      <div className="LoginView">
        <div className="card">
          <div className="card-body">
            <div className="card-logo">
              <img src={logoName} alt="DosXtremos" />
            </div>
            <h1 className="sr-only">{t("sign_in")}</h1>
            <Form>
              <Form.Group className="mb-4" controlId="emailOrUsername">
                <Form.Label className="sr-only">{t("email_or_user")}</Form.Label>
                <Form.Control
                  type="text"
                  size="lg"
                  value={emailOrUsername}
                  placeholder={t("email_or_user")}
                  onChange={(e) => setEmailOrUsername(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="password">
                <Form.Label className="sr-only">{t("password")}</Form.Label>
                <Form.Control
                  type="password"
                  size="lg"
                  value={password}
                  placeholder={t("password")}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </Form.Group>

              <Button variant="primary" size="lg" className="w-100" onClick={handleLogin}>
                {t("sign_in")}
              </Button>

              <NavLink className="w-100 mt-4 text-center" href="/request-reset-password">
                {t("forgot_password")}
              </NavLink>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
