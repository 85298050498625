import React, { Children } from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { t } from "i18next";
import { Key } from "@phosphor-icons/react";

interface MenuItem {
  label: string;
  to: string;
  children?: MenuItem[];
  icon?: any;
}

interface SidebarProps {
  items: MenuItem[];
}

function createMenuItem(item: MenuItem, isChild = false) {
  if (item.children) {
    return (
      <NavDropdown
        title={
          <>
            {item.icon}
            <span className={!isChild ? "d-none d-lg-inline" : ""}>
              {item.label}
            </span>
          </>
        }
        key={item.to}
        id={""}
      >
        {item.children.map((item) => {
          return createMenuItem(item, true);
        })}
      </NavDropdown>
    );
  }

  return (
    <Nav.Item key={item.to}>
      <NavLink to={item.to} className="nav-link hover-translate text-black-50">
        {item.icon}{" "}
        <span className={!isChild ? "d-none d-lg-inline" : ""}>
          {item.label}
        </span>
      </NavLink>
    </Nav.Item>
  );
}

function Sidebar(props: SidebarProps) {
  const { items } = props;

  return (
    <Nav className="flex-column sidebar p-3">
      <Navbar.Brand href="#home">
        <img src="/logo.png" className="d-none d-lg-inline" alt="Dosxtremos" width="120" />
      </Navbar.Brand>

      {items.map((item: MenuItem) => {
        return createMenuItem(item);
      })}

      <Nav.Item className="border-top bottom">
        <NavLink to="/login" className="nav-link hover-translate text-black-50">
          <Key size={24} />
          <span className="d-none d-lg-inline">{t("logout")}</span>
        </NavLink>
      </Nav.Item>
    </Nav>
  );
}

export default Sidebar;
